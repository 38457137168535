import React, { useEffect } from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import AOS from 'aos';
import 'aos/dist/aos.css';

const CountNumbers = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Ensures the animation triggers only once
  });
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <section className="theme-bg-blue pt-4">
      <div className="container-custom">
        <div
          ref={ref}
        
          className="count-numbers-heading leading-none text-white font-Montserrat-Regular font-medium text-center"
        >
          {inView && (
            <CountUp
              start={95800.786}
              end={95902.786}
              duration={4.8}
              separator=","
              decimals={3}
              decimal="."
              prefix="$ "
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default CountNumbers;
