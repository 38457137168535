import React from "react";
import ContacBannerLeftImg from "../assets/imgs/contact-banner-left-img.png";

const ContactBanner = () => {
  return (
    <section className="theme-bg-blue inner-page-banner relative flex items-center justify-center overflow-x-clip">
      <img
        className="absolute z-10 contact-left-img"
        src={ContacBannerLeftImg}
        alt=""
      />
      <span className="blurredcirc blurredcirc-home-banner-one theme-bg-seagreen block absolute opacity-anim-delay-one opacity-anim"></span>
      <span className="blurredcirc blurredcirc-home-banner-two theme-bg-purpletype block absolute opacity-anim-delay-zero-six opacity-anim"></span>
      <h3
        className="inner-page-banner-shadowed-heading uppercase leading-none font-Bebas-Regular"
        data-aos="fade-down"
        data-aos-duration="800"
        data-aos-delay="600"
      >
        Contact Us
      </h3>
      <h3 className="inner-page-banner-normal-heading absolute font-size-58px theme-color-seagreen leading-none font-PlusJakartaSans-ExtraBold"
       data-aos="fade-up"
       data-aos-duration="800"
       data-aos-delay="600">
        Contact Us
      </h3>
    </section>
  );
};

export default ContactBanner;
