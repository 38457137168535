import React, { useState, useRef, useEffect } from "react";
import BitcoinImg from "../assets/imgs/bitcoin-img.png";
import etherium from "../assets/imgs/etherium.png";
import solana from "../assets/imgs/solana.png";
import bnb from "../assets/imgs/bnb.png";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import AOS from 'aos';
import 'aos/dist/aos.css';

const InvestBreakdown = () => {
  const sliderRef = useRef(null);
  const [progressBarIndex, setProgressBarIndex] = useState(0);
  const [percentTime, setPercentTime] = useState(0);
  const tickRef = useRef(null);
  const time = 1;

  useEffect(() => {
    startProgressbar();
    return () => {
      clearInterval(tickRef.current);
    };
  }, [progressBarIndex]);

  const startProgressbar = () => {
    resetProgressbar();
    setPercentTime(0);
    tickRef.current = setInterval(interval, 10);
  };

  const interval = () => {
    if (
      sliderRef.current.innerSlider.list
        .querySelector(`.slick-track div[data-index="${progressBarIndex}"]`)
        .getAttribute("aria-hidden") === "true"
    ) {
      const currentIndex = parseInt(
        sliderRef.current.innerSlider.list
          .querySelector('.slick-track div[aria-hidden="false"]')
          .getAttribute("data-index"),
        10
      );
      setProgressBarIndex(currentIndex);
      startProgressbar();
    } else {
      setPercentTime((prevPercentTime) => {
        const newPercentTime = prevPercentTime + 1 / (time + 5);
        if (newPercentTime >= 100) {
          sliderRef.current.slickNext();
          const newIndex = (progressBarIndex + 1) % 3;
          setProgressBarIndex(newIndex);
          startProgressbar();
        }
        return newPercentTime;
      });
    }
  };

  const resetProgressbar = () => {
    document.querySelector(".inProgress").style.width = "0%";
    clearInterval(tickRef.current);
  };

  // const handleProgressBarClick = (index) => {
  //   clearInterval(tickRef.current);
  //   sliderRef.current.slickGoTo(index, false);
  //   setProgressBarIndex(index);
  //   startProgressbar();
  // };

  const handlePrevClick = () => {
    sliderRef.current.slickPrev();
    const newIndex = (progressBarIndex - 1 + 3) % 3;
    setProgressBarIndex(newIndex);
    resetProgressbar();
    startProgressbar();
  };

  const handleNextClick = () => {
    sliderRef.current.slickNext();
    const newIndex = (progressBarIndex + 1) % 3;
    setProgressBarIndex(newIndex);
    resetProgressbar();
    startProgressbar();
  };

  const settings = {
    infinite: true,
    arrows: false,
    dots: false,
    autoplay: false,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 999,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <section className="invest-breakdown-sec theme-bg-blue">
      <div className="container-custom">
        <div className="flex flex-col gap-7"  data-aos="fade-down" data-aos-duration="1000">
          <h3 className="leading-none font-Gilroy-Semibold font-size-48px text-white lg:text-left text-center">
          Total Value Locked
          </h3>
          <Slider ref={sliderRef} className="slider single-item" {...settings}>
            <div className="invest-breakdown-card flex flex-col items-center justify-center gap-3">
              <img
                className="invest-breakdown-card-img"
                src={BitcoinImg}
                alt=""
              />
              <h4 className="font-Gilroy-Regular font-size-32px theme-color-grey-white leading-none">
                Bitcoin
              </h4>
              <h4 className="font-Gilroy-Semibold font-size-30px text-white leading-none">
                USD 47,581.521
              </h4>
            </div>
            <div className="invest-breakdown-card flex flex-col items-center justify-center gap-3">
              <img
                className="invest-breakdown-card-img"
                src={etherium}
                alt=""
              />
              <h4 className="font-Gilroy-Regular font-size-32px theme-color-grey-white leading-none">
                Etherem
              </h4>
              <h4 className="font-Gilroy-Semibold font-size-30px text-white leading-none">
                USD 23,790.760
              </h4>
            </div>
            <div className="invest-breakdown-card flex flex-col items-center justify-center gap-3">
              <img className="invest-breakdown-card-img" src={solana} alt="" />
              <h4 className="font-Gilroy-Regular font-size-32px theme-color-grey-white leading-none">
                Solana
              </h4>
              <h4 className="font-Gilroy-Semibold font-size-30px text-white leading-none">
                USD 11,895.380
              </h4>
            </div>
            <div className="invest-breakdown-card flex flex-col items-center justify-center gap-3">
              <img className="invest-breakdown-card-img" src={bnb} alt="" />
              <h4 className="font-Gilroy-Regular font-size-32px theme-color-grey-white leading-none">
                BNB
              </h4>
              <h4 className="font-Gilroy-Semibold font-size-30px text-white leading-none">
                USD 12,635.125
              </h4>
            </div>
            <div className="invest-breakdown-card flex flex-col items-center justify-center gap-3">
              <img className="invest-breakdown-card-img" src={bnb} alt="" />
              <h4 className="font-Gilroy-Regular font-size-32px theme-color-grey-white leading-none">
                BNB
              </h4>
              <div className="flex items-center gap-2">
                <h4 className="font-Gilroy-Semibold font-size-30px text-slate-300 leading-none">
                  USD 34879.4
                </h4>
                <span className="tag-of-card font-Gilroy-Regular theme-color-blue">
                  -5.48%
                </span>
              </div>
              {/* <span className="font-Lato-Regular font-size-20px text-white">
                Let’s know more
              </span> */}
            </div>
          </Slider>
          <div className="progressBarContainer flex items-center">
              <span className="progressBar">
                <div
                  className="inProgress"
                  style={{ width: `${percentTime}%` }}
                ></div>
              </span>
              <div className="buttonContainer flex items-center gap-4 ml-4">
                <button className="buttonContainer-button" onClick={handlePrevClick}><FontAwesomeIcon icon={faArrowLeft} /></button>
                <button className="buttonContainer-button" onClick={handleNextClick}><FontAwesomeIcon icon={faArrowRight} /></button>
              </div>
            </div>
        </div>
      </div>
    </section>
  );
};

export default InvestBreakdown;
