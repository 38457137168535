import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import LeftIcfImg from "../assets/imgs/about-we-are-img.png";



const faqs = [
  {
      id: 1,
      header: "1. Compliance",
      text: `Compliance: Hexazone’s compliance experts ensure that our operations align with regulatory standards. We prioritize security and legal compliance, providing peace of mind to our investors. Our robust KYC (Know Your Customer) procedures and AML (Anti-Money Laundering) protocols safeguard your investments.`
  },
  {
      id: 2,
      header: "2. Crypto Investment Management",
      text: `Crypto Investment Management: Our seasoned team carefully manages a diversified portfolio of crypto assets. From Bitcoin to altcoins, we make informed decisions to optimize returns. Our investment strategies are data-driven, combining fundamental analysis, technical indicators, and market sentiment to stay ahead of trends.`
  },
  {
      id: 3,
      header: "3. Blockchain Technology",
      text: `Blockchain Technology: Understanding the intricacies of blockchain technology is crucial. Hexazone leverages this knowledge to enhance fund management strategies and stay ahead of industry trends. Our developers actively contribute to open-source projects, ensuring that our platform remains secure and scalable.`
  }
]



const AccordionItem = (props) => {
  const contentEl = useRef();
  const { handleToggle, active, faq } = props;
  const { header, id, text } = faq;

  return (
      <div className="rc-accordion-card">
          <div className="rc-accordion-header">
              <div className={`rc-accordion-toggle px-3 py-5 ${active === id ? 'active' : ''}`} onClick={() => handleToggle(id)}>
                  <h5 className="rc-accordion-title font-Gilroy-Regular">{header}</h5>
                  <svg className="rc-accordion-icon" width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 1L9 8L0.999999 1" stroke="white" stroke-linecap="round"/>
</svg>


              </div>
          </div>
          <div ref={contentEl} className={`rc-collapse ${active === id ? 'show' : ''}`} style={
              active === id
                  ? { height: contentEl.current.scrollHeight }
                  : { height: "0px" }
          }>
              <div className="rc-accordion-body">
                  <p className='mb-0 font-Gilroy-Regular'>{text}</p>
              </div>
          </div>
      </div>
  )
}





const AboutWhoWeAre = () => {

  const [active, setActive] = useState(null);

  const handleToggle = (index) => {
      if (active === index) {
          setActive(null);
      } else {
          setActive(index);
      }
  }
  


  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className="about-who-we-are-sec relative theme-bg-blue">
      <span className="blurredcirc theme-bg-purpletype block absolute -left-28 z-10 bottom-0 opacity-anim-delay-zero-six opacity-anim"></span>
      <div className="container-custom relative z-10">
        <div className="flex md:flex-row md:gap-0 gap-8 flex-col items-center">
          <div
            data-aos="fade-right"
            data-aos-duration="1500"
            className="md:w-1/2 w-full md:text-start text-center"
          >
            <div className="flex flex-col gap-4">
              <h4 className="text-white font-Gilroy-Semibold font-size-48px leading-none">
                Who We Are
              </h4>

              <p className="font-Gilroy-Medium font-size-20px text-white">
                At DDAF, we’re passionate about simplifying crypto investments
                for everyone. With over 7 years of experience in compliance,
                crypto investment management, and blockchain technology, we’ve
                created an innovative solution that combines the best of both
                worlds: index funds and cryptocurrencies.
              </p>
              <h5 class="expertise ex-gradient font-Gilroy-Semibold text-white px-2 py-3 lg:w-1/2">Our Expertise</h5>

            <div>
            {faqs.map((faq, index) => {
                                     return (
                                            <AccordionItem key={index} active={active} handleToggle={handleToggle} faq={faq} />
                                        )
                                    })
                                }
            </div>


            </div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="1500"
            data-aos-delay="200"
            className="md:w-1/2 w-full"
          >
            <div className="md:w-10/12 w-full ml-auto">
              <div className="glowing-box relative">
                <span className="blurredcirc theme-bg-seagreen block absolute top-24 right-0 opacity-anim-delay-one opacity-anim"></span>
                <span className="blurredcirc theme-bg-purpletype -bottom-24 left-0 block absolute opacity-anim-delay-zero-six opacity-anim"></span>
                <img
                  className="relative z-10 w-full py-4"
                  src={LeftIcfImg}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutWhoWeAre;
