import React, { useEffect } from "react";
import CardIconone from "../assets/imgs/card-icon-one.png";
import CardIcontwo from "../assets/imgs/card-icon-two.png";
import CardIconthree from "../assets/imgs/card-icon-three.png";
import AOS from "aos";
import "aos/dist/aos.css";

const CardsSec = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className="cards-sec theme-bg-blue">
      <div className="container-custom">
        <div className="flex flex-col gap-7">
          <h3
            data-aos="fade-down"
            data-aos-duration="1000"
            className="leading-none font-Gilroy-Semibold font-size-48px text-white"
          >
            Best Features For Users Convenience
          </h3>
          <div className="lg:flex lg:justify-between">
            <div className="flex flex-col items-center cards-box lg:mb-0 mb-5 justify-center"
             data-aos="fade-down"
             data-aos-duration="800"
             data-aos-delay="200">
              <img className="cards-box-img" src={CardIconone} alt="" />
              <h4 className="font-size-32px font-Gilroy-Medium text-white leading-none text-center">
                Quick Onboarding
              </h4>
              <p className="font-Lato-Regular font-size-20px text-slate-300 leading-tight text-center">
                {" "}
                Fast onboarding use to login with your existing Crypto wallet
              </p>
            </div>
            <div className="flex flex-col items-center cards-box lg:mb-0 mb-5 justify-center"
             data-aos="fade-down"
             data-aos-duration="800"
             data-aos-delay="400">
              <img className="cards-box-img" src={CardIcontwo} alt="" />
              <h4 className="font-size-32px font-Gilroy-Medium text-white leading-none text-center">
                High Security
              </h4>
              <p className="font-Lato-Regular font-size-20px text-slate-300 leading-tight text-center">
                Assets are held using industry best practices using best of
                cold, hot and warm wallet mechanism.
              </p>
            </div>
            <div className="flex flex-col items-center cards-box justify-center"
             data-aos="fade-down"
             data-aos-duration="800"
             data-aos-delay="600">
              <img className="cards-box-img" src={CardIconthree} alt="" />
              <h4 className="font-size-32px font-Gilroy-Medium text-white leading-none text-center">
                AI Bot
              </h4>
              <p className="font-Lato-Regular font-size-20px text-slate-300 leading-tight text-center">
                AI bot rebalancing the portfolio using weight-based strategy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CardsSec;
