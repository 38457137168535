import React from "react";

// Row One Imgs
import RowOneImg1 from "../assets/imgs/about-coin/row-one/row-one-1.png";
import RowOneImg2 from "../assets/imgs/about-coin/row-one/row-one-2.png";
import RowOneImg3 from "../assets/imgs/about-coin/row-one/row-one-3.png";
import RowOneImg4 from "../assets/imgs/about-coin/row-one/row-one-4.png";
import RowOneImg5 from "../assets/imgs/about-coin/row-one/row-one-5.png";
import RowOneImg6 from "../assets/imgs/about-coin/row-one/row-one-6.png";
import RowOneImg7 from "../assets/imgs/about-coin/row-one/row-one-7.png";
import RowOneImg8 from "../assets/imgs/about-coin/row-one/row-one-8.png";
import RowOneImg9 from "../assets/imgs/about-coin/row-one/row-one-9.png";
import RowOneImg10 from "../assets/imgs/about-coin/row-one/row-one-10.png";
import RowOneImg11 from "../assets/imgs/about-coin/row-one/row-one-11.png";
import RowOneImg12 from "../assets/imgs/about-coin/row-one/row-one-12.png";
import RowOneImg13 from "../assets/imgs/about-coin/row-one/row-one-13.png";
import RowOneImg14 from "../assets/imgs/about-coin/row-one/row-one-14.png";
import RowOneImg15 from "../assets/imgs/about-coin/row-one/row-one-15.png";
import RowOneImg16 from "../assets/imgs/about-coin/row-one/row-one-16.png";
// Row One Imgs

// Row Two Imgs
import RowTwoImg1 from "../assets/imgs/about-coin/row-two/row-two-1.png";
import RowTwoImg2 from "../assets/imgs/about-coin/row-two/row-two-2.png";
import RowTwoImg3 from "../assets/imgs/about-coin/row-two/row-two-3.png";
import RowTwoImg4 from "../assets/imgs/about-coin/row-two/row-two-4.png";
import RowTwoImg5 from "../assets/imgs/about-coin/row-two/row-two-5.png";
import RowTwoImg6 from "../assets/imgs/about-coin/row-two/row-two-6.png";
import RowTwoImg7 from "../assets/imgs/about-coin/row-two/row-two-7.png";
import RowTwoImg8 from "../assets/imgs/about-coin/row-two/row-two-8.png";
import RowTwoImg9 from "../assets/imgs/about-coin/row-two/row-two-9.png";
import RowTwoImg10 from "../assets/imgs/about-coin/row-two/row-two-10.png";
import RowTwoImg11 from "../assets/imgs/about-coin/row-two/row-two-11.png";
import RowTwoImg12 from "../assets/imgs/about-coin/row-two/row-two-12.png";
import RowTwoImg13 from "../assets/imgs/about-coin/row-two/row-two-13.png";
import RowTwoImg14 from "../assets/imgs/about-coin/row-two/row-two-14.png";
import RowTwoImg15 from "../assets/imgs/about-coin/row-two/row-two-15.png";
import RowTwoImg16 from "../assets/imgs/about-coin/row-two/row-two-16.png";
import RowTwoImg17 from "../assets/imgs/about-coin/row-two/row-two-17.png";
// Row Two Imgs

// Row Three Imgs
import RowThreeImg1 from "../assets/imgs/about-coin/row-three/row-three-1.png";
import RowThreeImg2 from "../assets/imgs/about-coin/row-three/row-three-2.png";
import RowThreeImg3 from "../assets/imgs/about-coin/row-three/row-three-3.png";
import RowThreeImg4 from "../assets/imgs/about-coin/row-three/row-three-4.png";
import RowThreeImg5 from "../assets/imgs/about-coin/row-three/row-three-5.png";
import RowThreeImg6 from "../assets/imgs/about-coin/row-three/row-three-6.png";
import RowThreeImg7 from "../assets/imgs/about-coin/row-three/row-three-7.png";
import RowThreeImg8 from "../assets/imgs/about-coin/row-three/row-three-8.png";
import RowThreeImg9 from "../assets/imgs/about-coin/row-three/row-three-9.png";
import RowThreeImg10 from "../assets/imgs/about-coin/row-three/row-three-10.png";
import RowThreeImg11 from "../assets/imgs/about-coin/row-three/row-three-11.png";
import RowThreeImg12 from "../assets/imgs/about-coin/row-three/row-three-12.png";
import RowThreeImg13 from "../assets/imgs/about-coin/row-three/row-three-13.png";
import RowThreeImg14 from "../assets/imgs/about-coin/row-three/row-three-14.png";
import RowThreeImg15 from "../assets/imgs/about-coin/row-three/row-three-15.png";
// Row Three Imgs

// Row Four Imgs
import RowFourImg1 from "../assets/imgs/about-coin/row-four/row-four-1.png";
import RowFourImg2 from "../assets/imgs/about-coin/row-four/row-four-2.png";
import RowFourImg3 from "../assets/imgs/about-coin/row-four/row-four-3.png";
import RowFourImg4 from "../assets/imgs/about-coin/row-four/row-four-4.png";
import RowFourImg5 from "../assets/imgs/about-coin/row-four/row-four-5.png";
import RowFourImg6 from "../assets/imgs/about-coin/row-four/row-four-6.png";
import RowFourImg7 from "../assets/imgs/about-coin/row-four/row-four-7.png";
import RowFourImg8 from "../assets/imgs/about-coin/row-four/row-four-8.png";
import RowFourImg9 from "../assets/imgs/about-coin/row-four/row-four-9.png";
import RowFourImg10 from "../assets/imgs/about-coin/row-four/row-four-10.png";
import RowFourImg11 from "../assets/imgs/about-coin/row-four/row-four-11.png";
import RowFourImg12 from "../assets/imgs/about-coin/row-four/row-four-12.png";
import RowFourImg13 from "../assets/imgs/about-coin/row-four/row-four-13.png";
import RowFourImg14 from "../assets/imgs/about-coin/row-four/row-four-14.png";
import RowFourImg15 from "../assets/imgs/about-coin/row-four/row-four-15.png";
import RowFourImg16 from "../assets/imgs/about-coin/row-four/row-four-16.png";
import RowFourImg17 from "../assets/imgs/about-coin/row-four/row-four-17.png";
// Row Four Imgs

const AboutSlider = () => {
  return (
    <section className="about-slider-sec theme-bg-blue overflow-hidden py-16">
      <div className="slider-before-after relative">
        <div className="slides-about-parent flex flex-col gap-8">
          <div className="flex items-center about-slider-row-one gap-4">
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg1} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg2} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg3} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg4} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg5} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg6} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg7} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg8} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg9} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg10} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg11} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg12} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg13} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg14} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg15} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg16} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg1} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg2} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg3} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg4} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg5} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg6} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg7} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg8} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg9} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg10} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg11} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg12} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg13} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg14} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg15} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowOneImg16} alt="" />
            </a>
          </div>

          <div className="flex items-center about-slider-row-two gap-4">
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg1} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg2} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg3} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg4} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg5} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg6} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg7} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg8} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg9} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg10} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg11} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg12} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg13} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg14} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg15} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg16} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg17} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg1} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg2} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg3} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg4} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg5} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg6} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg7} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg8} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg9} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg10} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg11} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg12} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg13} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg14} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg15} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg16} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowTwoImg17} alt="" />
            </a>
          </div>

          <div className="flex items-center about-slider-row-three gap-4">
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowThreeImg1} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowThreeImg2} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowThreeImg3} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowThreeImg4} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowThreeImg5} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowThreeImg6} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowThreeImg7} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowThreeImg8} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowThreeImg9} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img
                className="about-slider-box-img"
                src={RowThreeImg10}
                alt=""
              />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img
                className="about-slider-box-img"
                src={RowThreeImg11}
                alt=""
              />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img
                className="about-slider-box-img"
                src={RowThreeImg12}
                alt=""
              />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img
                className="about-slider-box-img"
                src={RowThreeImg13}
                alt=""
              />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img
                className="about-slider-box-img"
                src={RowThreeImg14}
                alt=""
              />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img
                className="about-slider-box-img"
                src={RowThreeImg15}
                alt=""
              />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowThreeImg1} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowThreeImg2} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowThreeImg3} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowThreeImg4} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowThreeImg5} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowThreeImg6} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowThreeImg7} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowThreeImg8} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowThreeImg9} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img
                className="about-slider-box-img"
                src={RowThreeImg10}
                alt=""
              />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img
                className="about-slider-box-img"
                src={RowThreeImg11}
                alt=""
              />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img
                className="about-slider-box-img"
                src={RowThreeImg12}
                alt=""
              />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img
                className="about-slider-box-img"
                src={RowThreeImg13}
                alt=""
              />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img
                className="about-slider-box-img"
                src={RowThreeImg14}
                alt=""
              />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img
                className="about-slider-box-img"
                src={RowThreeImg15}
                alt=""
              />
            </a>
          </div>

          <div className="flex items-center about-slider-row-four gap-4">
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg1} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg2} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg3} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg4} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg5} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg6} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg7} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg8} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg9} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg10} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg11} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg12} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg13} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg14} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg15} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg16} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg17} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg1} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg2} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg3} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg4} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg5} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg6} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg7} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg8} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg9} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg10} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg11} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg12} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg13} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg14} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg15} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg16} alt="" />
            </a>
            <a href="/" className="about-slider-box flex items-center justify-center">
              <img className="about-slider-box-img" src={RowFourImg17} alt="" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSlider;
