import React from 'react';
import Header from "../components/Header";
import ContactBanner from '../components/ContactBanner';
import ContactMain from '../components/ContactMain';
import Footer from '../components/Footer';

const Contact = () => {
  return (
    <>
    <Header />
    <ContactBanner />
    <ContactMain />
    <Footer />
    </>
  )
}

export default Contact