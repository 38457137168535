import React from "react";
import HeaderImg from "../assets/imgs/DDAF_Logo_Black.png";

const Loader = () => {
  return (
    <div className="loader fixed left-0 z-40 flex items-center justify-center text-black">
      <span
        className="flex justify-center items-center gap-2 w-max"
      >
        <img
          className="header-logo-img header-logo-img-anim w-3/5"
          src={HeaderImg}
          alt=""
        />
      </span>
    </div>
  );
};

export default Loader;
