import React from "react";
import Header from "../components/Header";
import AboutBanner from "../components/AboutBanner";
import AboutWhoWeAre from "../components/AboutWhoWeAre";
import AboutMissionVision from "../components/AboutMissionVision";
import AboutFeature from "../components/AboutFeature";
// import AboutMeetTeam from "../components/AboutMeetTeam";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import CountNumbers from "../components/CountNumbers";
import InvestBreakdown from "../components/InvestBreakdown";

const About = () => {
  return (
    <>
      <Header />
      <AboutBanner />
      <AboutWhoWeAre />
      <AboutFeature />
      <CountNumbers />
      <InvestBreakdown />
      {/* <AboutMeetTeam /> */}
      <AboutMissionVision />
      <Newsletter />
      <Footer />
    </>
  );
};

export default About;
