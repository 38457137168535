import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import HeaderImg from "../assets/imgs/DDAF_Logo_White.png";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <header className="header-nav-parent absolute top-0 w-full z-20">
      <div className="container-custom">
        {isMobile ? (
          <div className="flex items-center justify-between">
            <Link
              onClick={scrollToTop}
              to="/"
              className="flex justify-start items-center gap-2 w-max"
            >
              <img
                className="header-logo-img-anim w-2/3"
                src={HeaderImg}
                alt=""
              />
              
            </Link>
            <div className="mobile-menu">
              <button
                onClick={() => setMenuOpen(!menuOpen)}
                className="menu-icon"
              >
                {menuOpen ? <FaTimes /> : <FaBars />}
              </button>
              {menuOpen && (
                <div className="mobile-menu-content font-Gilroy-Bold">
                  <Link
                  onClick={scrollToTop}
                  className="text-white font-size-20px leading-none"
                  to="/icf"
                >
                  $ ICF
                </Link>
                  <Link
                    onClick={scrollToTop}
                    className="text-white font-size-20px leading-none"
                    to="/about"
                  >
                    About Us
                  </Link>
                  <Link
                    onClick={scrollToTop}
                    className="text-white font-size-20px leading-none"
                    to="/contact"
                  >
                    Contact Us
                  </Link>
                  <Link className="same-btn font-Gilroy-Regular" to="">
                    Trade Now
                  </Link>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="header-grid grid items-center">
            <Link
              onClick={scrollToTop}
              to="/"
              className="flex justify-start items-center gap-2 w-max"
            >
              <img
                className="header-logo-img header-logo-img-anim w-2/6"
                src={HeaderImg}
                alt=""
              />
            </Link>
            <div className="flex items-center justify-center lg:gap-10 md:gap-4 gap-10 font-Gilroy-Bold">
              <Link
                onClick={scrollToTop}
                className="text-white font-size-20px leading-none"
                to="/icf"
              >
                $ ICF
              </Link>
              <Link
                onClick={scrollToTop}
                className="text-white font-size-20px leading-none"
                to="/about"
              >
                About Us
              </Link>
              <Link
                onClick={scrollToTop}
                className="text-white font-size-20px leading-none"
                to="/contact"
              >
                Contact Us
              </Link>
            </div>

            <div className="flex items-center justify-end">
              <Link className="same-btn font-Gilroy-Regular" to="">
                Trade Now
              </Link>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
