import React, { useEffect } from "react";
import BulleseyeImg from "../assets/imgs/bullseye-icon.png";
import EyeImg from "../assets/imgs/eye-vision-icon.png";
import AOS from "aos";
import "aos/dist/aos.css";

const AboutMissionVision = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className="about-mission-vision-sec relative theme-bg-blue">
      <div className="container-custom">
        <div className="flex lg:flex-row flex-col lg:gap-0 gap-4 lg:justify-between">
          <div
            data-aos="fade-down"
            data-aos-duration="1000"
            className="flex flex-col items-center text-center gap-4 about-mission-vision-box"
          >
            <img className="about-mis-vis-box-img" src={BulleseyeImg} alt="" />
            <h4 className="font-Gilroy-Semibold font-size-32px text-white leading-none">
              Our Mission
            </h4>
            <p className="text-slate-300 font-Gilroy-Regular font-size-20px">
            Our mission is to democratize access to the crypto market. We believe that everyone should have the opportunity to invest in digital assets without the complexity and risk associated with individual project selection. DDAF provides a seamless way to gain exposure to the entire crypto market through a single token.
            </p>
          </div>
          <div
            data-aos="fade-down"
            data-aos-duration="800"
            data-aos-delay="200"
            className="flex flex-col items-center text-center gap-4 about-mission-vision-box"
          >
            <img className="about-mis-vis-box-img" src={EyeImg} alt="" />
            <h4 className="font-Gilroy-Semibold font-size-32px text-white leading-none">
              Our Vision
            </h4>
            <p className="text-slate-300 font-Gilroy-Regular font-size-20px">
            At Hexazone, we envision a future where crypto markets are accessible to everyone. Our mission is to empower individuals worldwide to participate in the digital economy, regardless of their background or expertise through our single token $DDAF.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMissionVision;
