import React, { useEffect } from "react";
import HomeBanner from "../components/HomeBanner";
// import WhatisICF from "../components/WhatisICF";
import CardsSec from "../components/CardsSec";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "../components/Loader";
import AboutSlider from "../components/AboutSlider";

const Home = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Loader />
      <HomeBanner />
      <AboutSlider />

      {/* <WhatisICF /> */}
      <section
        style={{ backgroundColor: "#1B1B2C" }}
        className="2xl:py-28 py-20 text-center"
      >
        <div className="container-custom mx-auto">
          <h3
            data-aos="fade-down"
            data-aos-duration="1000"
            className="leading-tight text-white font-size-58px font-PlusJakartaSans-ExtraBold px-8"
          >
            <span style={{ color: "#5068E7" }}>
              Investing in digital assets
            </span>{" "}
            is high-risk in nature and individual asset investing requires
            significant time and resource invested.
          </h3>
        </div>
      </section>
      <section className="home-key-point-sec theme-bg-blue 2xl:py-24 py-16">
        <div className="container-custom mx-auto">
          <div className="box-of-key-points md:flex flex-col hidden relative">
            <div className="flex items-center">
              <div
                className="w-1/2 flex flex-col gap-2 2xl:px-10 px-8 2xl:py-8 py-6"
                data-aos="fade-right"
                data-aos-duration="100"
                data-aos-delay="100"
              >
                <span className="leading-none font-size-48px font-PlusJakartaSans-Bold text-white">
                  Key Issues Analysis
                </span>
              </div>
              <div
                className="w-1/2 flex flex-col gap-2"
                data-aos="fade-left"
                data-aos-duration="100"
                data-aos-delay="100"
              >
                <span className="leading-none font-size-48px font-PlusJakartaSans-Bold text-white">
                  Key Benefits Analysis
                </span>
              </div>
            </div>
            <div className="flex flex-col ">
              <div className="center-line-of-home-box"></div>
              <div className="flex items-center border-t border-white">
                <div
                  className="w-1/2 flex flex-col gap-2 2xl:px-10 px-8 2xl:py-8 py-6"
                  data-aos="fade-right"
                  data-aos-duration="400"
                  data-aos-delay="200"
                >
                  <span className="leading-none font-size-25px font-PlusJakartaSans-Bold theme-color-seagreen">
                    Difficulty in Portfolio Management
                  </span>
                  <p className="leading-tight font-size-20px font-PlusJakartaSans-Regular text-white">
                    Buying and selling individual cryptocurrencies often involve
                    transaction fees, which can accumulate, especially for
                    frequent traders. These costs can erode investment returns
                    over time, particularly for smaller investors.
                  </p>
                </div>
                <div
                  className="w-1/2 flex flex-col gap-2"
                  data-aos="fade-left"
                  data-aos-duration="400"
                  data-aos-delay="200"
                >
                  <span className="leading-none font-size-25px font-PlusJakartaSans-Bold theme-color-seagreen">
                    AI Bot Rebalancing
                  </span>
                  <p className="leading-tight font-size-20px font-PlusJakartaSans-Regular text-white">
                    Index tokens offer lower transaction fees as we can purchase
                    off-market at OTC rates which are at a discount to the spot
                    rates and have comparatively lower brokerage fees. We also
                    have access to deep liquidity through our institutional
                    liquidity partners.
                  </p>
                </div>
              </div>
              <div className="flex items-center border-t border-white">
                <div
                  className="w-1/2 flex flex-col gap-2 2xl:px-10 px-8 2xl:py-8 py-6"
                  data-aos="fade-right"
                  data-aos-duration="400"
                  data-aos-delay="200"
                >
                  <span className="leading-none font-size-25px font-PlusJakartaSans-Bold theme-color-seagreen">
                    High Volatility
                  </span>
                  <p className="leading-tight font-size-20px font-PlusJakartaSans-Regular text-white">
                    Cryptocurrency markets are highly volatile, with prices
                    subject to rapid and extreme fluctuations. Investing in
                    individual projects exposes investors to significant price
                    volatility, which can lead to substantial gains or losses in
                    a short period.
                  </p>
                </div>
                <div
                  className="w-1/2 flex flex-col gap-2"
                  data-aos="fade-left"
                  data-aos-duration="400"
                  data-aos-delay="200"
                >
                  <span className="leading-none font-size-25px font-PlusJakartaSans-Bold theme-color-seagreen">
                    Risk Mitigation
                  </span>
                  <p className="leading-tight font-size-20px font-PlusJakartaSans-Regular text-white">
                    By spreading investment across multiple cryptocurrencies,
                    index tokens can help mitigate the impact of
                    underperformance or failure of any single project, enhancing
                    overall portfolio stability.
                  </p>
                </div>
              </div>
              <div className="flex items-center border-t border-white">
                <div
                  className="w-1/2 flex flex-col gap-2 2xl:px-10 px-8 2xl:py-8 py-6"
                  data-aos="fade-right"
                  data-aos-duration="400"
                  data-aos-delay="200"
                >
                  <span className="leading-none font-size-25px font-PlusJakartaSans-Bold theme-color-seagreen">
                    Lack of Diversification
                  </span>
                  <p className="leading-tight font-size-20px font-PlusJakartaSans-Regular text-white">
                    Investing in a single cryptocurrency limits diversification,
                    increasing overall portfolio risk. If the chosen project
                    underperforms or faces challenges, there is no buffer from
                    other assets to mitigate losses. Diversification is
                    essential for managing risk and optimising returns in
                    investment portfolios.
                  </p>
                </div>
                <div
                  className="w-1/2 flex flex-col gap-2"
                  data-aos="fade-left"
                  data-aos-duration="400"
                  data-aos-delay="200"
                >
                  <span className="leading-none font-size-25px font-PlusJakartaSans-Bold theme-color-seagreen">
                    Convenience
                  </span>
                  <p className="leading-tight font-size-20px font-PlusJakartaSans-Regular text-white">
                    Investing in an index token offers a convenient way to gain
                    exposure to the cryptocurrency market without the need for
                    extensive research or active portfolio management. Investors
                    can access diversified exposure to crypto assets through a
                    single investment, one-click access through $DDAF.
                  </p>
                </div>
              </div>
              <div className="flex items-center border-t border-white">
                <div
                  className="w-1/2 flex flex-col gap-2 2xl:px-10 px-8 2xl:py-8 py-6"
                  data-aos="fade-right"
                  data-aos-duration="400"
                  data-aos-delay="200"
                >
                  <span className="leading-none font-size-25px font-PlusJakartaSans-Bold theme-color-seagreen">
                    Limited Expertise and Research
                  </span>
                  <p className="leading-tight font-size-20px font-PlusJakartaSans-Regular text-white">
                    Assessing the potential of each individual cryptocurrency
                    requires significant expertise and research. Understanding
                    the technology, team, market dynamics, and regulatory
                    environment of each project can be time-consuming and
                    challenging for individual investors, increasing the risk of
                    making uninformed investment decisions.
                  </p>
                </div>
                <div
                  className="w-1/2 flex flex-col gap-2"
                  data-aos="fade-left"
                  data-aos-duration="400"
                  data-aos-delay="200"
                >
                  <span className="leading-none font-size-25px font-PlusJakartaSans-Bold theme-color-seagreen">
                    Lower Costs
                  </span>
                  <p className="leading-tight font-size-20px font-PlusJakartaSans-Regular text-white">
                    Index tokens offer lower transaction fees as we can purchase
                    off-market at OTC rates which are at a discount to the spot
                    rates and have comparatively lower brokerage fees. We also
                    have access to deep liquidity through our institutional
                    liquidity partners.
                  </p>
                </div>
              </div>
              <div className="flex items-center border-t border-white">
                <div
                  className="w-1/2 flex flex-col gap-2 2xl:px-10 px-8 2xl:py-8 py-6"
                  data-aos="fade-right"
                  data-aos-duration="400"
                  data-aos-delay="200"
                >
                  <span className="leading-none font-size-25px font-PlusJakartaSans-Bold theme-color-seagreen">
                    Market Manipulation and Fraud
                  </span>
                  <p className="leading-tight font-size-20px font-PlusJakartaSans-Regular text-white">
                    The cryptocurrency market is susceptible to manipulation and
                    fraudulent activities due to its decentralized and
                    relatively unregulated nature. Investing in individual
                    projects exposes investors to the risk of falling victim to
                    pump-and-dump schemes, fraudulent ICOs (Initial Coin
                    Offerings), or other forms of market manipulation.
                  </p>
                </div>
                <div
                  className="w-1/2 flex flex-col gap-2"
                  data-aos="fade-left"
                  data-aos-duration="400"
                  data-aos-delay="200"
                >
                  <span className="leading-none font-size-25px font-PlusJakartaSans-Bold theme-color-seagreen">
                    Diversification
                  </span>
                  <p className="leading-tight font-size-20px font-PlusJakartaSans-Regular text-white">
                    Index tokens typically hold a diversified portfolio of
                    cryptocurrencies, reducing concentration risk and exposing a
                    broader range of assets within a single investment vehicle.
                    Using a weight-based investment strategy, investments are
                    properly managed and diverse.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="box-of-key-points md:hidden flex-col flex relative">
            <div className="flex flex-col items-center">
              <div className="flex flex-col gap-2 2xl:px-10 px-8 2xl:py-8 py-6"
              data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="500">
                <span className="leading-none font-size-48px font-PlusJakartaSans-Bold text-white">
                  Key Issues Analysis
                </span>
              </div>
              <div className="flex flex-col gap-2 2xl:px-10 px-8 2xl:py-8 py-6"data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="500">
                <span className="leading-none font-size-25px font-PlusJakartaSans-Bold theme-color-seagreen">
                  Difficulty in Portfolio Management
                </span>
                <p className="leading-tight font-size-20px font-PlusJakartaSans-Regular text-white">
                  Buying and selling individual cryptocurrencies often involve
                  transaction fees, which can accumulate, especially for
                  frequent traders. These costs can erode investment returns
                  over time, particularly for smaller investors.
                </p>
              </div>
              <div className="flex flex-col gap-2 2xl:px-10 px-8 2xl:py-8 py-6"data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="500">
                <span className="leading-none font-size-25px font-PlusJakartaSans-Bold theme-color-seagreen">
                  High Volatility
                </span>
                <p className="leading-tight font-size-20px font-PlusJakartaSans-Regular text-white">
                  Cryptocurrency markets are highly volatile, with prices
                  subject to rapid and extreme fluctuations. Investing in
                  individual projects exposes investors to significant price
                  volatility, which can lead to substantial gains or losses in a
                  short period.
                </p>
              </div>
              <div className="flex flex-col gap-2 2xl:px-10 px-8 2xl:py-8 py-6"data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="500">
                <span className="leading-none font-size-25px font-PlusJakartaSans-Bold theme-color-seagreen">
                  Risk Mitigation
                </span>
                <p className="leading-tight font-size-20px font-PlusJakartaSans-Regular text-white">
                  By spreading investment across multiple cryptocurrencies,
                  index tokens can help mitigate the impact of underperformance
                  or failure of any single project, enhancing overall portfolio
                  stability.
                </p>
              </div>
              <div className="flex flex-col gap-2 2xl:px-10 px-8 2xl:py-8 py-6"data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="500">
                <span className="leading-none font-size-25px font-PlusJakartaSans-Bold theme-color-seagreen">
                  Lack of Diversification
                </span>
                <p className="leading-tight font-size-20px font-PlusJakartaSans-Regular text-white">
                  Investing in a single cryptocurrency limits diversification,
                  increasing overall portfolio risk. If the chosen project
                  underperforms or faces challenges, there is no buffer from
                  other assets to mitigate losses. Diversification is essential
                  for managing risk and optimising returns in investment
                  portfolios.
                </p>
              </div>
              <div className="flex flex-col gap-2 2xl:px-10 px-8 2xl:py-8 py-6"data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="500">
                <span className="leading-none font-size-25px font-PlusJakartaSans-Bold theme-color-seagreen">
                  Limited Expertise and Research
                </span>
                <p className="leading-tight font-size-20px font-PlusJakartaSans-Regular text-white">
                  Assessing the potential of each individual cryptocurrency
                  requires significant expertise and research. Understanding the
                  technology, team, market dynamics, and regulatory environment
                  of each project can be time-consuming and challenging for
                  individual investors, increasing the risk of making uninformed
                  investment decisions.
                </p>
              </div>
              <div className="flex flex-col gap-2 2xl:px-10 px-8 2xl:py-8 py-6"data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="500">
                <span className="leading-none font-size-25px font-PlusJakartaSans-Bold theme-color-seagreen">
                  Market Manipulation and Fraud
                </span>
                <p className="leading-tight font-size-20px font-PlusJakartaSans-Regular text-white">
                  The cryptocurrency market is susceptible to manipulation and
                  fraudulent activities due to its decentralized and relatively
                  unregulated nature. Investing in individual projects exposes
                  investors to the risk of falling victim to pump-and-dump
                  schemes, fraudulent ICOs (Initial Coin Offerings), or other
                  forms of market manipulation.
                </p>
              </div>
            </div>
          </div>
          <div className="box-of-key-points md:hidden flex-col flex relative mt-8">
            <div className="flex flex-col items-center">
              <div className="flex flex-col gap-2 pt-8">
                <span className="leading-none font-size-48px font-PlusJakartaSans-Bold text-white">
                  Key Benefits Analysis
                </span>
              </div>
              <div className="flex flex-col gap-2 2xl:px-10 px-8 2xl:py-8 py-6"data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="500">
                <span className="leading-none font-size-25px font-PlusJakartaSans-Bold theme-color-seagreen">
                  AI Bot Rebalancing
                </span>
                <p className="leading-tight font-size-20px font-PlusJakartaSans-Regular text-white">
                  Index tokens offer lower transaction fees as we can purchase
                  off-market at OTC rates which are at a discount to the spot
                  rates and have comparatively lower brokerage fees. We also
                  have access to deep liquidity through our institutional
                  liquidity partners.
                </p>
              </div>
              <div className="flex flex-col gap-2 2xl:px-10 px-8 2xl:py-8 py-6"data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="500">
                <span className="leading-none font-size-25px font-PlusJakartaSans-Bold theme-color-seagreen">
                  Convenience
                </span>
                <p className="leading-tight font-size-20px font-PlusJakartaSans-Regular text-white">
                  Investing in an index token offers a convenient way to gain
                  exposure to the cryptocurrency market without the need for
                  extensive research or active portfolio management. Investors
                  can access diversified exposure to crypto assets through a
                  single investment, one-click access through $DDAF.
                </p>
              </div>
              <div className="flex flex-col gap-2 2xl:px-10 px-8 2xl:py-8 py-6"data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="500">
                <span className="leading-none font-size-25px font-PlusJakartaSans-Bold theme-color-seagreen">
                  Lower Costs
                </span>
                <p className="leading-tight font-size-20px font-PlusJakartaSans-Regular text-white">
                  Index tokens offer lower transaction fees as we can purchase
                  off-market at OTC rates which are at a discount to the spot
                  rates and have comparatively lower brokerage fees. We also
                  have access to deep liquidity through our institutional
                  liquidity partners.
                </p>
              </div>
              <div className="flex flex-col gap-2 2xl:px-10 px-8 2xl:py-8 py-6"data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="500">
                <span className="leading-none font-size-25px font-PlusJakartaSans-Bold theme-color-seagreen">
                  Diversification
                </span>
                <p className="leading-tight font-size-20px font-PlusJakartaSans-Regular text-white">
                  Index tokens typically hold a diversified portfolio of
                  cryptocurrencies, reducing concentration risk and exposing a
                  broader range of assets within a single investment vehicle.
                  Using a weight-based investment strategy, investments are
                  properly managed and diverse.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-roadmap-sec 2xl:py-16 md:py-12 pt-12 pb-4">
        <div className="container-custom mx-auto">
          <h3
            data-aos="fade-down"
            data-aos-duration="1000"
            className="leading-none font-Gilroy-Semibold font-size-48px text-white"
          >
            Roadmap
          </h3>
          <div
            className="flex md:flex-row md:gap-0 gap-4 flex-col items-center pt-16 pb-6 relative"
          >
            <div className="roadmap-line-back absolute z-10 w-full">
              <span className="roadmap-line-back-circle absolute top-1/2 -left-4 transform -translate-y-1/2"></span>
              <span className="roadmap-line-back-circle absolute top-1/2 -right-4 transform -translate-y-1/2"></span>
            </div>
            <div className="md:w-1/4 flex flex-col gap-4 relative z-20" 
            data-aos="fade-down"
                  data-aos-duration="800"
                  data-aos-delay="200">
              <div className="circle-roadmap flex flex-col items-center justify-center gap-2 mx-auto">
                <span className="text-white font-size-20px text-center font-Gilroy-Bold">
                  Q2 <br />
                  2024
                </span>
              </div>
              <p className="font-size-18px text-white font-Gilroy-Regular leading-tight text-center opacity-0 transition-all ease-in-out">
              Launch of $DDAF with $100 price per token and $100,000 assets under management
              </p>
            </div>
            <div className="md:w-1/4 flex flex-col gap-4 relative z-20"
            data-aos="fade-down"
            data-aos-duration="800"
            data-aos-delay="400">
              <div className="circle-roadmap flex flex-col items-center justify-center gap-2 mx-auto">
                <span className="text-white font-size-20px text-center font-Gilroy-Bold">
                  Q3 <br />
                  2024
                </span>
              </div>
              <p className="font-size-18px text-white font-Gilroy-Regular leading-tight text-center opacity-0 transition-all ease-in-out">
              Launch of web app to allow for buy/sell of $DDAF (TVL under management crosses) $1M
              </p>
            </div>
            <div className="md:w-1/4 flex flex-col gap-4 relative z-20"
            data-aos="fade-down"
            data-aos-duration="800"
            data-aos-delay="600">
              <div className="circle-roadmap flex flex-col items-center justify-center gap-2 mx-auto">
                <span className="text-white font-size-20px text-center font-Gilroy-Bold">
                  Q4 <br />
                  2024
                </span>
              </div>
              <p className="font-size-18px text-white font-Gilroy-Regular leading-tight text-center opacity-0 transition-all ease-in-out">
              Listing on Major Dexs. (TVL under management $2M)
              </p>
            </div>
            <div className="md:w-1/4 flex flex-col gap-4 relative z-20"
            data-aos="fade-down"
            data-aos-duration="800"
            data-aos-delay="800">
              <div className="circle-roadmap flex flex-col items-center justify-center gap-2 mx-auto">
                <span className="text-white font-size-20px text-center font-Gilroy-Bold">
                  Q1 <br />
                  2025
                </span>
              </div>
              <p className="font-size-18px text-white font-Gilroy-Regular leading-tight text-center opacity-0 transition-all ease-in-out">
              Listing on tier 1 exchanges (TVL under management $10M)
              </p>
            </div>
          </div>
        </div>
      </section>
      <CardsSec />
      <Newsletter />
      <Footer />
    </>
  );
};

export default Home;
