import React from "react";
import AppstoreIcon from "../assets/imgs/download-app-store.png";
import PlaystoreIcon from "../assets/imgs/download-play-store.png";
import Fbicon from "../assets/imgs/fb-icon.png";
import Linkedinicon from "../assets/imgs/linkedin-icon.png";
import HandWaveicon from "../assets/imgs/hand-wave-icon.png";
import { Link } from "react-router-dom";

const Footer = () => {
  
  const currentYear = new Date().getFullYear()

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  };
  return (
    <footer className="theme-bg-blue footer-sec footer-border-top">
      <div className="footer-main">
        <div className="container-custom">
          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="flex flex-col gap-2 text-white lg:w-1/2 lg:text-start text-center"
            data-aos="fade-right"
            data-aos-duration="800"
            data-aos-delay="600"
            >
              <h4 className="font-PlusJakartaSans-Medium font-size-35px leading-none">
                Ready for an innovative trading strategy?
              </h4>
              <h4 className="font-PlusJakartaSans-Medium font-size-35px leading-none">
                Let's start now!
              </h4>
              <p className="font-Gilroy-Regular font-size-18px">
                Get start now! And buy and sell cryptocurrency It simplifies the
                process of buying and selling digital from anywhere in the
                world.
              </p>
            </div>
            <div className="flex lg:flex-col md:flex-row flex-col lg:justify-normal justify-between gap-8 lg:mt-0 mt-5"
            data-aos="fade-left"
            data-aos-duration="800"
            data-aos-delay="600">
              <div className="flex items-center lg:justify-end md:justify-start justify-center gap-4">
                <div className="play-and-app-icon relative transition-opacity">
                <img className="opacity-55 hover:opacity-100 transition-opacity" src={AppstoreIcon} alt="Coming Soon" />
                <span className="absolute text-white -top-12 left-6 bg-blue-900 px-2 py-1 rounded shadow-lg">Coming Soon</span>
                </div>
                
                <div className="play-and-app-icon relative transition-opacity">
                <img className="opacity-55 hover:opacity-100 transition-opacity" src={PlaystoreIcon} alt="" />
                <span className="absolute text-white -top-12 left-6 bg-blue-900 px-2 py-1 rounded shadow-lg">Coming Soon</span>
                </div>
                
              </div>
              <div className="flex items-center lg:justify-end justify-center gap-4">
                <img className="footer-social-icon" src={Fbicon} alt="" />
                <img className="footer-social-icon" src={Linkedinicon} alt="" />
                <img className="footer-social-icon" src={HandWaveicon} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom footer-border-top">
        <div className="container-custom">
          <div className="flex md:flex-row flex-col items-center justify-between">
            <div className="flex items-center justify-center gap-4 font-PlusJakartaSans-Regular">
              <Link onClick={scrollToTop} className="text-white font-size-18px leading-none">
                $ ICF
              </Link>
              <Link onClick={scrollToTop} className="text-white font-size-18px leading-none" to="/about">
                About Us
              </Link>
              <Link onClick={scrollToTop} className="text-white font-size-18px leading-none" to="/contact">
                Contact Us
              </Link>
            </div>
            <p className="font-size-14px text-white font-Gilroy-Regular md:mt-0 mt-6">© {currentYear} BlockXone. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
