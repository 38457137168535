import React from 'react'

const Newsletter = () => {
  return (
    <section className="newsletter-sec theme-bg-blue">
        <div className="container-custom">
            <div className="flex flex-col justify-center text-center gap-4"
             data-aos="fade-up"
             data-aos-duration="800"
             data-aos-delay="600">
                <h4 className='font-Gilroy-Semibold font-size-48px text-white leading-none'>Join our news letter</h4>
                <p className='font-Lato-Regular font-size-20px text-slate-300'>Let's join our platform community and start investing in cryptocurrency</p>
                <div className="relative w-max mx-auto">
                    <input className="newsletter-input font-Lato-Regular font-size-20px text-black" placeholder="Enter Your Email" type="email" />
                    <button className="newsletter-btn absolute right-0 top-0 font-Gilroy-Regular" type="button">Contact us</button>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Newsletter