import React, { useState } from "react";
import ContactMainLeftImg from "../assets/imgs/contact-main-left-img.png";
import emailjs from "emailjs-com";
const ContactMain = () => {
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phone: "",
    message: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    
    const {name, value} = e.target;
    setFormData({
      ...formData,
      [name]: value,
    })
   
  }

  const handleSubmit = (e) => {
  e.preventDefault();


  emailjs.sendForm('mufaddal_service_emailjs', 'mufaddal_template_id', e.target, '-23oV2gFkc7GIVR65')
      .then((result) => {
        console.log(result.text);
        setIsSubmitted(true);
      }, (error) => {
        console.log(error.text);
      });

  }


  return (
    <section className="contact-main-sec theme-bg-blue">
      <div className="container-custom">
        <div className="flex items-center justify-between flex-wrap">
          <div className="contact-main-content"
           data-aos="fade-right"
           data-aos-duration="800"
           data-aos-delay="600"
           >
            <div className="lg:w-10/12 lg:ml-auto lg:mb-0 mb-10">
              <div className="glowing-box relative">
                <span className="blurredcirc theme-bg-seagreen block absolute top-24 right-0 opacity-anim-delay-one opacity-anim"></span>
                <span className="blurredcirc theme-bg-purpletype -bottom-24 left-0 block absolute opacity-anim-delay-zero-six opacity-anim"></span>
                <img
                  className="relative z-10 w-full py-4"
                  src={ContactMainLeftImg}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="contact-main-content contact-main-content-right flex flex-col gap-4"
           data-aos="fade-left"
           data-aos-duration="800"
           data-aos-delay="600"
           >
            <h3 className="font-Gilroy-Semibold font-size-48px text-white leading-none">
              Let's Connect with Us
            </h3>
            
            {isSubmitted ? (

              <div className="p-2 bg-green-800 items-center text-green-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                <span className="flex rounded-full bg-green-500 uppercase px-2 py-1 text-xs font-bold mr-3">Success</span>
                <span className="font-semibold mr-2 text-left flex-auto">We have successfully received your query. We'll shortly respond you!</span>
               </div>
           
            ) : (
            
            <form id="contact-form" className="flex flex-col gap-2" onSubmit={handleSubmit}>
              <input
                className="input-contact textarea-input font-Gilroy-Regular font-size-18px"
                type="text"
                placeholder="Full Name"
                name="fullname"
                value={formData.fullname}
                onChange={handleChange}
              />
              <input
                className="input-contact textarea-input font-Gilroy-Regular font-size-18px"
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              <input
                className="input-contact input-number-contact textarea-input font-Gilroy-Regular font-size-18px"
                type="number"
                placeholder="Phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
              <textarea
                className="textarea-contact textarea-input font-Gilroy-Regular font-size-18px"
                placeholder="Have anything to say?"
                name="message"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
               <div className="flex-items-center justify-start">
              <button className="same-btn font-Gilroy-Regular" type="submit">
                Send
              </button>
            </div>
            </form>
            )}

           
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactMain;
