import React, { useEffect } from "react";
import CardIconone from "../assets/imgs/Investment.png";
import CardIcontwo from "../assets/imgs/commitment.png";
import CardIconthree from "../assets/imgs/Robot.png";
import AOS from "aos";
import "aos/dist/aos.css";

const AboutFeature = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className="cards-sec theme-bg-blue">
      <div className="container-custom">
        <div className="flex flex-col gap-7">
          <h3
            data-aos="fade-down"
            data-aos-duration="1000"
            className="leading-none font-Gilroy-Semibold font-size-48px text-white"
          >
            Feature
          </h3>
          <div className="lg:flex lg:justify-between">
            <div
              className="flex flex-col items-center cards-box lg:mb-0 mb-5 justify-center"
              data-aos="fade-down"
              data-aos-duration="800"
              data-aos-delay="200"
            >
              <img className="cards-box-img" src={CardIconone} alt="" />
              <h4 className="font-size-32px font-Gilroy-Medium text-white leading-none text-center">
                Self Custody
              </h4>
              
            </div>
            <div
              className="flex flex-col items-center cards-box lg:mb-0 mb-5 justify-center"
              data-aos="fade-down"
              data-aos-duration="800"
              data-aos-delay="400"
            >
              <img className="cards-box-img" src={CardIcontwo} alt="" />
              <h4 className="font-size-32px font-Gilroy-Medium text-white leading-none text-center">
                Simple Onboarding
              </h4>
             
            </div>
            <div
              className="flex flex-col items-center cards-box justify-center"
              data-aos="fade-down"
              data-aos-duration="800"
              data-aos-delay="600"
            >
              <img className="cards-box-img" src={CardIconthree} alt="" />
              <h4 className="font-size-32px font-Gilroy-Medium text-white leading-none text-center">
               Full Open Source
              </h4>
              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutFeature;
