import React from "react";
import Header from "../components/Header";
import ICFBanner from "../components/ICFBanner";
import ICFFirstSec from "../components/ICFFirstSec";
import CardsSec from "../components/CardsSec";
import CountNumbers from "../components/CountNumbers";
import InvestBreakdown from "../components/InvestBreakdown";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";

const ICF = () => {
  return (
    <>
      <Header />
      <ICFBanner />

<div className="theme-bg-blue py-36 text-7xl font-PlusJakartaSans-Medium font-bold text-blue-500 uppercase relative flex items-center justify-center">
  Coming Soon
</div>

      {/* <ICFFirstSec /> */}
      {/* <CardsSec />
      <CountNumbers /> */}
      {/* <InvestBreakdown /> */}
      {/* <Newsletter /> */}
      <Footer />
    </>
  );
};

export default ICF;
