import React from "react";
import AboutBannerRightImg from "../assets/imgs/about-banner-left.png";

const AboutBanner = () => {
  return (
    <section className="theme-bg-blue inner-page-banner relative flex items-center justify-center overflow-x-clip">
      <img
        className="absolute z-10 about-right-img"
        src={AboutBannerRightImg}
        alt=""
      />
      <span className="blurredcirc blurredcirc-home-banner-one theme-bg-seagreen block absolute opacity-anim-delay-one opacity-anim"></span>
      <span className="blurredcirc blurredcirc-home-banner-two theme-bg-purpletype block absolute opacity-anim-delay-zero-six opacity-anim"></span>
      <h3
        className="inner-page-banner-shadowed-heading uppercase leading-none font-Bebas-Regular"
        data-aos="fade-down"
        data-aos-duration="800"
        data-aos-delay="100"
      >
        About Us
      </h3>
      <h3 className="inner-page-banner-normal-heading absolute font-size-58px theme-color-seagreen leading-none font-PlusJakartaSans-ExtraBold"
      data-aos="fade-up"
      data-aos-duration="800"
      data-aos-delay="100">
        About Us
      </h3>
    </section>
  );
};

export default AboutBanner;
