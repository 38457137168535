import React, { useEffect, useState } from "react";
import HomeBannerLeft from "../assets/imgs/home-banner-left.png";
import HomeBannerRight from "../assets/imgs/home-banner-right.png";
import BtnIcon from "../assets/imgs/btn-icon.png";
import { Link } from "react-router-dom";
import Header from "./Header";
import AOS from 'aos';
import 'aos/dist/aos.css';

const HomeBanner = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  useEffect(() => {
    
    AOS.init();
    
    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      setPosition({ x: clientX, y: clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };

  }, [])
  return (
    <section className="lg:h-screen home-banner-sec relative theme-bg-blue overflow-x-clip pb-16 lg:pb-0">
        <span className="blurredcirc top-0 left-0 theme-bg-seagreen block absolute opacity-anim-delay-one opacity-anim"></span>
        <span className="blurredcirc blurredcirc-home-banner-two theme-bg-purpletype block absolute opacity-anim-delay-zero-six opacity-anim"></span>
      <Header />
      <div className="container-custom lg:h-full">
        <img
          className="absolute homebanner-left-img"
          src={HomeBannerLeft}
          alt=""
        />
        <div className="home-banner-content-parent flex items-center lg:flex-row flex-col lg:justify-start justify-center lg:h-full pt-28 lg:pt-0">
          <div data-aos="fade-right" data-aos-duration="1500" data-aos-delay="2500" className="lg:w-1/2 w-full relative lg:text-start text-center z-10">
            <div className="flex flex-col gap-5">
              <h3 style={{lineHeight: "1.15"}} className="font-size-58px font-PlusJakartaSans-ExtraBold theme-color-seagreen">
              Unlocking <br /> Opportunities <br/>
                <span className="text-white">Explore Decentralized</span> <br />
                <span className="text-white">Digital Asset Fund</span>
              </h3>
              <p className="text-white font-size-20px font-Gilroy-Medium">
                We're passionate about simplifying crypto trading for everyone
              </p>
              <Link className="same-btn font-Gilroy-Regular gap-2 lg:mx-0 mx-auto" to="">See Details <img className="btn-icon-img" src={BtnIcon} alt="" /></Link>
            </div>
          </div>
          <div data-aos="fade-left" data-aos-duration="1500" data-aos-delay="2500" className="lg:w-1/2 w-full relative z-10">
            <img
              className="home-banner-right-img"
              src={HomeBannerRight}
              alt=""
              style={{
                transform: `translate(${position.x * 0.05}px, ${position.y * 0.05}px)`
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;
