import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import ICF from "./pages/ICF";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <main className="overflow-x-hidden">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/icf" element={<ICF />}></Route>
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default App;
